
import Modal from "react-modal";
import ReactModal from "react-modal";
import { useRecoilState } from "recoil";
import React, { useEffect, useState } from "react";
import { repayCustomStyles, resendCustomStyles } from "../../../styles/theme";
import { isRepayPopupOpenState, isResendPopupOpenState, resendLoadingState, resendReasonDetailState, resendReasonState, resendUserCardNumberState } from "../../../recoil/atoms";
import { BlueSpan, CloseButton, FlexBox, RedSpan, ResendModalBox, ResendModalContent, ResendModalHeader, ResendModalLi, ResendModalSelect, ResendModalTextarea, ResendModalUl, ResendPopupBtns, RoundButton, TextDiv, TitleDiv } from "./style";
import closeIcon from "../../../assets/images/icon-close.svg";
import { paycardHypen } from "../../../util";
import { MoonLoader } from "react-spinners";
const parse = require("html-react-parser");

Modal.setAppElement("#root");

export const Reasons = {
    DEFAULT: "",
    LOSS: "LOSS",
    BROKEN: "BROKEN",
    NOT_RECEIVED: "NOT_RECEIVED",
    ETC: "ETC"
} as const;

type Reason = typeof Reasons[keyof typeof Reasons];

function RepayPopup({ onClick, onCloseClick }: { onClick?: Function, onCloseClick?: Function }) {
    const [isOpen, setIsOpen] = useRecoilState(isResendPopupOpenState);
    const [resendUserCardNumber, setResendUserCardNumber] = useRecoilState(resendUserCardNumberState);
    const [resendReason, setResendReason] = useRecoilState(resendReasonState);
    const [resendReasonDetail, setResendReasonDetail] = useRecoilState(resendReasonDetailState);
    const [resendLoading, setResendLoading] = useRecoilState(resendLoadingState);
    function closeModal() {
        setIsOpen(false);
        setResendReason(Reasons.DEFAULT);
        setResendReasonDetail('');
        onCloseClick && onCloseClick();
    }
    function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setResendReason(e.target.value as Reason);
        if (e.target.value !== Reasons.NOT_RECEIVED && e.target.value !== Reasons.ETC) {
            setResendReasonDetail('');
        }
    }
    function handleTextareaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setResendReasonDetail(e.target.value);
    }
    function validateAndHandleClick(onClick: Function | undefined, resendReason: Reason | string) {
        if (typeof onClick !== 'function') {
            console.error("onClick is not a function");
            return;
        }
        if (resendReason === Reasons.DEFAULT) {
            console.error("신청 사유를 선택해주세요.");
            return;
        }
        if (resendReason === Reasons.ETC && resendReasonDetail === '') {
            console.error("상세사유를 입력해주세요.");
            return;
        }
        if (resendReason === Reasons.NOT_RECEIVED && resendReasonDetail === '') {
            console.error("상세사유를 입력해주세요.");
            return;
        }
        onClick();
    }

    ReactModal.defaultStyles.overlay!.backgroundColor = "rgba( 0, 0, 0, 0.5)";
    return (
        <Modal isOpen={isOpen} style={resendCustomStyles} contentLabel="EvPlug popup" shouldCloseOnOverlayClick={true}>
            <ResendModalBox width="540px">
                <ResendModalHeader>
                    <TitleDiv>멤버십카드 재발급 신청하기</TitleDiv>
                    <CloseButton onClick={closeModal}><img src={closeIcon} alt="닫기" /></CloseButton>
                </ResendModalHeader>
                <ResendModalContent>
                    <FlexBox style={{ marginBottom: '18px' }}>
                        <TextDiv width="30%">멤버십 카드 번호</TextDiv>
                        <TextDiv width="70%">{paycardHypen(resendUserCardNumber)}</TextDiv>
                    </FlexBox>
                    <FlexBox style={{ marginBottom: '18px' }}>
                        <TextDiv width="30%">신청사유<RedSpan>*</RedSpan></TextDiv>
                        <TextDiv width="70%" fontWeight="400">
                            <ResendModalSelect name="reasons" id="reasons" onChange={handleSelectChange}>
                                <option value={Reasons.DEFAULT}>신청 사유를 선택해주세요.</option>
                                <option value={Reasons.LOSS}>분실</option>
                                <option value={Reasons.BROKEN}>파손</option>
                                <option value={Reasons.NOT_RECEIVED}>미수령 또는 오배송</option>
                                <option value={Reasons.ETC}>기타</option>
                            </ResendModalSelect>
                        </TextDiv>
                    </FlexBox>
                    {(resendReason === Reasons.ETC || resendReason === Reasons.NOT_RECEIVED) && (
                        <FlexBox style={{ marginBottom: '18px', 'flexDirection': 'column', 'alignItems': 'start' }}>
                            <TextDiv fontWeight="500">상세사유<RedSpan>*</RedSpan></TextDiv>
                            <ResendModalTextarea placeholder="상세사유를 입력해주세요." onChange={handleTextareaChange}></ResendModalTextarea>
                        </FlexBox>
                    )}{
                        (resendReason === Reasons.DEFAULT || resendReason === Reasons.LOSS || resendReason === Reasons.BROKEN) ? <ResendModalUl>
                            <ResendModalLi>· 카드 재발급 신청 시 기존 카드는 <RedSpan>사용불가</RedSpan> 상태가 되며 더이상 충전이 불가합니다.</ResendModalLi>
                            <ResendModalLi>· 분실 또는 파손의 사유로 재발급하시는 경우 <BlueSpan>5,000원</BlueSpan>의 재발급 비용이 발생합니다.</ResendModalLi>
                            <ResendModalLi>· 재발급 비용은 현재 멤버십 카드에 연결된 결제카드로 결제됩니다.</ResendModalLi>
                            <ResendModalLi>· 새로 발급된 멤버십 카드는 영업일 5일이내로 배송됩니다.</ResendModalLi>
                        </ResendModalUl> : <ResendModalUl>
                            <ResendModalLi>· 카드 재발급 신청 시 기존 카드는 <RedSpan>사용불가</RedSpan> 상태가 되며 더이상 충전이 불가합니다.</ResendModalLi>
                            <ResendModalLi>· 담당자 상세 사유 확인 후 멤버십카드 재발급이 처리됩니다.</ResendModalLi>
                            <ResendModalLi>· 주소 오입력 등 사용자 과실의 경우 재발급 비용이 청구될 수 있습니다.</ResendModalLi>
                            <ResendModalLi>· 새로 발급된 멤버십 카드는 영업일 5일이내로 배송됩니다.</ResendModalLi>
                        </ResendModalUl>
                    }
                    {(resendReason === Reasons.LOSS || resendReason === Reasons.BROKEN) && (
                        <div style={{ textAlign: 'center', fontSize: '14px', marginTop: '20px' }}>
                            ※ 결제카드로 <BlueSpan>5,000원</BlueSpan>의 재발급 비용이 결제됩니다.
                        </div>
                    )}
                    <ResendPopupBtns>
                        <RoundButton
                            fill={
                                resendReason !== Reasons.DEFAULT &&
                                !(resendReason === Reasons.ETC && resendReasonDetail === '') &&
                                !(resendReason === Reasons.NOT_RECEIVED && resendReasonDetail === '')
                            }
                            width="300px"
                            onClick={() => validateAndHandleClick(onClick, resendReason)}
                        >
                            멤버십카드 재발급 신청하기
                        </RoundButton>
                    </ResendPopupBtns>
                </ResendModalContent>
            </ResendModalBox>
            {resendLoading && <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                position: "fixed",
                backgroundColor: "rgba(255, 255, 255, 0.6)",
                top: 0,
                left: 0,
                zIndex: 1200,
            }}>
                <MoonLoader color="#ff6900" size={70} cssOverride={{
                    display: "block",
                    margin: "0 auto",
                }} />
            </div>}
        </Modal >
    );
}


export default RepayPopup;
