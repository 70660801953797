
import Modal from "react-modal";
import ReactModal from "react-modal";
import { useRecoilState } from "recoil";
import React, { useEffect } from "react";
import { repayCustomStyles, resendConfirmCustomStyles } from "../../../styles/theme";
import { isRepayPopupOpenState, isResendConfirmOpenState, resendReasonDetailState, resendReasonState, resendUserCardNumberState } from "../../../recoil/atoms";
import { RectButton, ResendConfirmBox, ResendConfirmPopupBtns, ResendModalBox, ResendPopupBtns, RoundButton } from "./style";
const parse = require("html-react-parser");

Modal.setAppElement("#root");

function ResendConfirm({ closeBtnText = '닫기', onClickBtnText = '확인', content, onClick, onCloseClick }: { closeBtnText?: string, onClickBtnText?: string, content: React.ReactNode, onClick?: Function, onCloseClick?: Function }) {
    const [isOpen, setIsOpen] = useRecoilState(isResendConfirmOpenState);
    const [resendUserCardNumber] = useRecoilState(resendUserCardNumberState);
    const [resendReason] = useRecoilState(resendReasonState);
    const [resendReasonDetail] = useRecoilState(resendReasonDetailState);

    function closeModal() {
        setIsOpen(false);
    }
    useEffect(() => {
        console.log(`isOpen: ${isOpen}`);
    }, [isOpen])

    ReactModal.defaultStyles.overlay!.backgroundColor = "rgba( 0, 0, 0, 0)";
    return (
        <Modal isOpen={isOpen} style={resendConfirmCustomStyles} contentLabel="EvPlug popup" shouldCloseOnOverlayClick={true}>
            <ResendConfirmBox width="360px">
                <div
                    style={{
                        'width': "100%",
                        'display': 'flex'
                    }}
                >
                    <div className="w-full" id="detailContent">
                        {typeof content === 'string' ?
                            <div dangerouslySetInnerHTML={{ __html: content }} /> : content}
                    </div>
                </div>
                <ResendConfirmPopupBtns>
                    {
                        onClick == null ?

                            <RectButton
                                fill={true}
                                width="160px"
                                onClick={() => {
                                    if (onCloseClick != null) {
                                        onCloseClick();
                                    }
                                    closeModal();
                                }}
                            >확인</RectButton> :
                            <>
                                <RectButton
                                    fill={false}
                                    width="160px"
                                    onClick={async () => {
                                        if (onCloseClick != null) {
                                            await onCloseClick();
                                        }
                                        closeModal();
                                    }}
                                >{closeBtnText}</RectButton>
                                <RectButton fill={true} width="160px" onClick={() => onClick()}>{onClickBtnText}</RectButton>
                            </>
                    }
                </ResendConfirmPopupBtns>
            </ResendConfirmBox>
        </Modal >
    );
}


export default ResendConfirm;
