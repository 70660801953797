///////////////////////////////////////////
// 요청 내용
///////////////////////////////////////////
export const NON_FREE_RESEND_REQUEST =
    <div style={{ textAlign: 'center', fontSize: '14px' }}>
        멤버십카드 재발급을 신청하시겠습니까?
        <br />
        결제카드로 <span style={{ fontWeight: '600' }}>5,000원</span>이 결제됩니다.
    </div>;

export const FREE_RESEND_REQUEST =
    <div style={{ textAlign: 'center', fontSize: '14px' }}>
        멤버십카드 재발급을 신청하시겠습니까?
    </div>;


///////////////////////////////////////////
// 응답 내용
///////////////////////////////////////////
export const SUCCESS_RESEND_RESPONSE =
    <div style={{ textAlign: 'center', fontSize: '14px' }}>
        재발급 신청이 완료되었습니다.
        <br />
        관리자 확인 후 재발급이 진행됩니다.
    </div>;

export const FAIL_DEFAULT_RESEND_RESPONSE =
    <div style={{ textAlign: 'center', fontSize: '14px' }}>
        재발급 신청에 실패했습니다.
    </div>;