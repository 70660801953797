/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blank, Div, Font, PageLayout } from "../../styles/commonStyle";
import { cardTitleColor, payInfoOrangeColor, payInfoSubTextColor } from "../../styles/theme";
import React, { useState } from "react";
import PayInfoSectionTitle from "./components/payInfoSectionTitle";
import { FastChargingContainer, KepcoContainer, PayInfoNavyTable, PayInfoOrangeTable, PayInfoTabButton, PayInfoTabUl, RoamingContainer, RoamingLink, ToggleTitle } from "./style";
import topArrow from "../../assets/images/payInfo-top-arrow.png";
import memberIcon from "../../assets/images/member_icon.png";
import nonMemberIcon from "../../assets/images/non_member_icon.png";

const UPDATE_DATE = '2025.01.15';
const TAB_LIST = ['공동주택 시설', '공동주택 외 시설', '시간 제한 부지'];

function PayInfoPage() {
  const [tab, setTab] = useState(0);
  const [toggle, setToggle] = useState(true);

  return (
    <PageLayout>
      <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
        요금 안내
      </Font>
      <Blank height="8px" />
      <Font fontSize="1rem" fontWeight="400" color={payInfoSubTextColor} changeFontSize="14px">
        {UPDATE_DATE} 업데이트
      </Font>
      <Blank height="12px" />
      <PayInfoSectionTitle title="완속 충전 요금" />
      <PayInfoTabUl>
        {TAB_LIST.map((str, idx) => {
          return (
            <li key={idx}>
              <PayInfoTabButton
                bool={idx == tab ? true : false}
                onClick={() => {
                  setTab(idx);
                }}>{str}</PayInfoTabButton>
            </li>
          )
        })}
      </PayInfoTabUl>
      {
        tab === 0 ? ApartmentTable() : tab === 1 ? NonApartmentTable() : TimeRestrictedTable()
      }
      <Blank height="40px" />
      <ToggleTitle bool={toggle}>
        <button onClick={() => setToggle(!toggle)}>한전 계절별·시간대별 구분표 <img src={topArrow} alt="화살표" /></button>
      </ToggleTitle>
      <Blank height="16px" />
      {
        toggle ? Kepco() : null
      }
      <Blank height="100px" />
      <PayInfoSectionTitle title="급속 충전 요금" />
      <FastChargingContainer>
        {MemberTable()}
        {NonMemberTable()}
      </FastChargingContainer>
      <Blank height="100px" />
      <PayInfoSectionTitle title="로밍 요금" />
      <RoamingContainer>
        <div className="description">
          전기차 충전인프라 공동이용서비스(로밍) 요금 안내
        </div>
        <RoamingLink to={'https://evplug.co.kr/notice/694'} target="_blank">로밍 요금 보기</RoamingLink>
      </RoamingContainer>
      <Blank height="100px" />
    </PageLayout >
  );
}

function ApartmentTable() {
  return (
    <PayInfoOrangeTable>
      <div className="unit">[단위: 원/kWh]</div>
      <div className="table">
        <div className="table_head">
          <ul>
            <li>구분</li>
            <li>여름철<br />(6~8월)</li>
            <li>봄·가을철<br />(3~5월, 9~10월)</li>
            <li>겨울철<br />(11~2월)</li>
          </ul>
        </div>
        <div className="table_body">
          <ul>
            <li>경부하 시간대</li>
            <li>285원</li>
            <li>285원</li>
            <li>300원</li>
          </ul>
          <ul>
            <li>중간부하 시간대</li>
            <li>297원</li>
            <li>292원</li>
            <li>287원</li>
          </ul>
          <ul>
            <li>최대부하 시간대</li>
            <li>320원</li>
            <li>280원</li>
            <li>300원</li>
          </ul>
        </div>
      </div>
      <ul className="description">
        <li>요금은 한전 기본료 변경 및 시장 현황에 따라 추후 변동될 수 있습니다.</li>
        <li>21년도 이전 설치 충전기에 한하여 충전 시작 시간을 기준으로 위 요금제가 적용됩니다.</li>
        <li>21년도 이후 설치 충전기에 한하여 충전 시간대별로 위 요금제가 적용됩니다.</li>
        <li>일부 충전소의 사정에 따라 적용 요금 및 과금 방식이 상이할 수 있습니다.</li>
      </ul>
    </PayInfoOrangeTable>
  )
}

function NonApartmentTable() {
  return (
    <PayInfoOrangeTable>
      <div className="unit">[단위: 원/kWh]</div>
      <div className="table">
        <div className="table_head">
          <ul>
            <li>구분</li>
            <li>여름철<br />(6~8월)</li>
            <li>봄·가을철<br />(3~5월, 9~10월)</li>
            <li>겨울철<br />(11~2월)</li>
          </ul>
        </div>
        <div className="table_body">
          <ul>
            <li>경부하 시간대</li>
            <li>295원</li>
            <li>290원</li>
            <li>300원</li>
          </ul>
          <ul>
            <li>중간부하 시간대</li>
            <li>314원</li>
            <li>292원</li>
            <li>312원</li>
          </ul>
          <ul>
            <li>최대부하 시간대</li>
            <li>330원</li>
            <li>295원</li>
            <li>320원</li>
          </ul>
        </div>
      </div>
      <ul className="description">
        <li>요금은 한전 기본료 변경 및 시장 현황에 따라 추후 변동될 수 있습니다.</li>
        <li>21년도 이전 설치 충전기에 한하여 충전 시작 시간을 기준으로 위 요금제가 적용됩니다.</li>
        <li>21년도 이후 설치 충전기에 한하여 충전 시간대별로 위 요금제가 적용됩니다.</li>
        <li>일부 충전소의 사정에 따라 적용 요금 및 과금 방식이 상이할 수 있습니다.</li>
      </ul>
    </PayInfoOrangeTable>
  )
}

function TimeRestrictedTable() {
  return (
    <PayInfoOrangeTable>
      <div className="unit">[단위: 원/kWh]</div>
      <div className="table">
        <div className="table_head">
          <ul>
            <li>공통</li>
          </ul>
        </div>
        <div className="table_body">
          <ul>
            <li>310원</li>
          </ul>
        </div>
      </div>
      <ul className="description">
        <li>요금은 한전 기본료 변경 및 시장 현황에 따라 추후 변동될 수 있습니다.</li>
        <li>21년도 이전 설치 충전기에 한하여 충전 시작 시간을 기준으로 위 요금제가 적용됩니다.</li>
        <li>21년도 이후 설치 충전기에 한하여 충전 시간대별로 위 요금제가 적용됩니다.</li>
        <li>일부 충전소의 사정에 따라 적용 요금 및 과금 방식이 상이할 수 있습니다.</li>
      </ul>
    </PayInfoOrangeTable>
  )
}

function Kepco() {
  return (
    <KepcoContainer>
      <PayInfoNavyTable>
        <div className="table">
          <div className="table_head">
            <ul>
              <li className="diagonal">
                <div className="rightTop">계절</div>
                <div className="leftBottom">시간대</div>
              </li>
              <li>여름철<br />(6~8월)</li>
              <li>봄·가을철<br />(3~5월, 9~10월)</li>
              <li>겨울철<br />(11~2월)</li>
            </ul>
          </div>
          <div className="table_body">
            <ul>
              <li>경부하</li>
              <li>22:00~08:00</li>
              <li>22:00~08:00</li>
              <li>22:00~08:00</li>
            </ul>
            <ul>
              <li>중간부하</li>
              <li>
                08:00~11:00<br />
                12:00~13:00<br />
                18:00~22:00
              </li>
              <li>
                08:00~11:00<br />
                12:00~13:00<br />
                18:00~22:00
              </li>
              <li>
                08:00~09:00<br />
                12:00~16:00<br />
                19:00~22:00
              </li>
            </ul>
            <ul>
              <li>최대부하</li>
              <li>
                11:00~12:00<br />
                13:00~18:00
              </li>
              <li>
                11:00~12:00<br />
                13:00~18:00
              </li>
              <li>
                09:00~12:00<br />
                16:00~19:00
              </li>
            </ul>
          </div>
        </div>
        <ul className="description">
          <li>제주특별자치도의 시간대별 구분은 모든 계절에 적용 (경부하 : 22시~08시, 중간부하 : 08시~16시, 최대부하 : 16시~22시)</li>
        </ul>
      </PayInfoNavyTable>
    </KepcoContainer>
  )
}

function MemberTable() {
  return (
    <PayInfoOrangeTable>
      <div className="title">
        <img src={memberIcon} alt="회원" />
        <div className="content">evPlug 회원</div>
      </div>
      <div className="table">
        <div className="table_head">
          <ul>
            <li>충전기 구분</li>
            <li>단가</li>
          </ul>
        </div>
        <div className="table_body">
          <ul>
            <li>30kW</li>
            <li>300원</li>
          </ul>
          <ul>
            <li>50kW</li>
            <li>323원</li>
          </ul>
          <ul>
            <li>100kW</li>
            <li>345원</li>
          </ul>
        </div>
      </div>
    </PayInfoOrangeTable>
  )
}

function NonMemberTable() {
  return (
    <PayInfoOrangeTable>
      <div className="title">
        <img src={nonMemberIcon} alt="비회원" />
        <div className="content">비회원</div>
        <div className="unit">[단위: 원/kWh]</div>
      </div>
      <div className="table">
        <div className="table_head">
          <ul>
            <li>충전기 구분</li>
            <li>단가</li>
          </ul>
        </div>
        <div className="table_body">
          <ul style={{ height: '100%' }}>
            <li>완속 / 급속</li>
            <li>430원</li>
          </ul>
        </div>
      </div>
    </PayInfoOrangeTable>
  )
}

export default PayInfoPage;
