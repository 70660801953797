import styled from "styled-components";


interface ResendModalBoxProps {
  width?: string;
  height?: string;
}
const ResendModalBox = styled.div<ResendModalBoxProps>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "100%"};
  padding: 0px;
  @media (max-width: 600px){
    width: 350px;
  }
`;

interface ResendConfirmBoxProps {
  width?: string;
  height?: string;
}

const ResendConfirmBox = styled.div<ResendConfirmBoxProps>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "100%"};
  padding: 50px 25px 20px 25px;
  @media (max-width: 600px){
    width: 350px;
  }
`

const ResendModalHeader = styled.div`
  width: 100%;
  padding-top :25px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const ResendModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 40px 40px 40px;
  @media (max-width: 600px){
    padding: 0px 20px 20px 20px;
  }
`

const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
`

const TitleDiv = styled.div`
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 600px){
    font-size: 16px;
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  @media (max-width: 600px){
    right: 20px;
  }
`

interface TextDivProps {
  width?: string;
  fontSize?: string;
  fontWeight?: string;
}

const TextDiv = styled.div<TextDivProps>`
  width: ${(props) => props.width ?? "100%"};
  font-size: ${(props) => props.fontSize ?? "14px"};
  font-weight: ${(props) => props.fontWeight ?? "500"};
  @media (max-width: 600px){
    font-size: 12px;
  }
`

const ResendModalSelect = styled.select`
  width: 100%;
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 6px;
  border: 1px solid #afafaf;
  border-radius: 4px;
  outline: none;
  @media (max-width: 600px){
    height: 32px;
    font-size: 12px;
  }
`

const ResendModalTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border: 1px solid #afafaf;
  border-radius: 4px;
  resize: none;
  outline: none;
  font-family: "Apple SD Gothic Neo", "Malgun Gothic", sans-serif;
  &:focus {
    border: 1px solid #ff8116;
  }
  &::placeholder {
    color: #c6c6c6;
  }
  @media (max-width: 600px){
    height: 80px;
    font-size: 12px;
  }
`

const ResendModalUl = styled.ul`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding: 18px 20px;
  background-color: #f1f1f1;
  border-radius: 4px;
`

const ResendModalLi = styled.li`
  font-size: 14px;
  font-weight: 400;
  @media (max-width: 600px){
    font-size: 10px;
  }
`

const ResendPopupBtns = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  gap: 12px;
  @media (max-width: 600px){
    margin-top: 20px;
  }
`

const ResendConfirmPopupBtns = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  gap: 12px;
  @media (max-width: 600px){
    margin-top: 20px;
  }
`

interface RoundButtonProps {
  width?: string;
  height?: string;
  fill: boolean;
}

const RoundButton = styled.button<RoundButtonProps>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "46px"};
  border: ${(props) => props.fill ? "none" : "1px solid #ff8116"};
  background-color: ${(props) => props.fill ? "#ff8116" : "none"};
  cursor: ${(props) => props.fill ? "pointer" : "not-allowed"};
  color: ${(props) => props.fill ? "#ffffff" : "#ff8116"};
  border-radius: 46px;
  font-size: 16px;
  font-weight: 700;
  @media (max-width: 600px){
    font-size: 14px;
    height: 40px;
  }
`

interface RectButtonProps {
  width?: string;
  height?: string;
  fill: boolean;
}

const RectButton = styled.button<RectButtonProps>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "44px"};
  border: ${(props) => props.fill ? "none" : "1px solid #c4c4c4"};
  background-color: ${(props) => props.fill ? "#ff6900" : "none"};
  color: ${(props) => props.fill ? "#ffffff" : "#000000"};
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 600px){
    font-size: 14px;
    height: 40px;
  }
`

const BlueSpan = styled.span`
  color: #2A51B6;
  font-weight: 600;
`

const RedSpan = styled.span`
  color: #F56754;
  font-weight: 600;
`

export { ResendModalBox, ResendPopupBtns, RoundButton, RectButton, ResendModalHeader, ResendModalContent, FlexBox, ResendModalTextarea, ResendModalSelect, ResendModalUl, ResendModalLi, TextDiv, TitleDiv, CloseButton, ResendConfirmBox, BlueSpan, RedSpan, ResendConfirmPopupBtns };
