import axios, { AxiosResponse } from "axios";
import { RESULT, ResponseModel } from "../model/response";
import { QueryType } from "../model/query";
import { ModelType } from "../model/modelType";
import Interceptors from "../interceptor";
import crypto from "crypto-es";

export enum APILIST {
  NOTICE = "api/v1/articles",
  CHARGERREQ = "api/v1/chargerInstallReq/store",
  FINDID = "api/v1/users/findId",
  CERTIFI_PHONE = "api/v1/users/authCode",
  CERTIFI_AUTHCODE = "api/v1/users/checkCode",
  FINDPW = "api/v1/users/findPw",
  STIPULATION = "api/v1/stipulations",
  CARCOMPANY = "api/v1/cars/company",
  CAR = "api/v1/cars",
  REGISTER_USER = "api/v1/users/store",
  USER = "api/v1/users",
  LOGIN = "api/v1/users/login",
  SHOWUSER = "api/v1/users/show",
  UPDATEUSER = "api/v1/users/update",
  UPDATE_PASSWORD = "api/v1/users/changePassword",
  UPDATE_PAYCARD = "api/v1/users/updatePaycard",
  USER_DELETE = "api/v1/users/delete",
  USER_CARD = "api/v1/users/user_cards",
  CHARGING = "api/v1/chargings",
  FIND_CHARGER_QR = "api/v1/qr",
  SMARTRO_TEST = "api/v1/qr/smartroPayment",
  SMARTRO = "api/v1/qr/smartroPaymentRoamingId",
  PLATFORM = "api/v1/qr/platform",
  POINT = "api/v2/member-cards/TD",
  POINT_TOKEN = "api/v2/auth/key",
  POINT_LIST = "api/v2/point-records/TD",
  POINT_EXPIRES = "api/v2/point-expires/TD",
  MEMBERSHIP_USER_GRADE = "api/v2/grade/member",
  MEMBERSHIP_JUST_GRADE = "api/v2/grade/just",
  CSMS_KEY = "api/v2/auth/api-key",
  CSMS_CHARGING = "api/v2/users",
  CSMS_REPAY = "api/v2/elctc-payments/repay-user",
  CSMS_PAID_FAIL = "api/v2/elctc-payments",
}
const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
});

export const _instance = Interceptors(baseApi);

const _instanceFile = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: { "Content-Type": "multipart/form-data" },
});

export async function request<T>(
  api: APILIST | string,
  method: string,
  query?: QueryType<T>,
  data?: any,
  formData?: FormData,
  token?: string,
  server?: 'POINT' | 'CSMS' | 'MEMBERSHIP',
): Promise<ResponseModel<T>> {
  let res: AxiosResponse<any, any>;
  try {
    let url = getUri(api, query, server);

    switch (method) {
      case "get":
        res = await _instance.get(url);
        break;
      case "post":
        res = await _instance.post(url, data);
        break;
      case "filePost":
        res = await _instanceFile.post(url, formData);
        break;
      case "put":
        res = await _instance.put(url, data);
        break;
      case "filePut":
        res = await _instanceFile.put(url, formData);
        break;
      case "delete":
        res = await _instance.delete(url);
        break;
      case "token":
        res = await _instance.get(url, {
          headers: { Authorization: token },
        });
        break;
      case "tokenPost":
        res = await _instance.post(url, data, {
          headers: { Authorization: token },
        });
        break;
      default:
        res = await _instance.get(url);
        break;
    }
    let result: any | undefined;
    //210 : ACCESS TOKEN 만료 status
    if (res.status === 210) {
      let refreshToken = window.sessionStorage.getItem("refreshToken");
      result = await request(APILIST.USER + "/refreshTokenCheck", "token", undefined, undefined, undefined, `Bearer ${encodeURIComponent(refreshToken!)}`).then(
        async (response: any) => {
          if (response?.result === RESULT.FAIL) {
            //재 로그인
            let sessionStorage = window.sessionStorage;
            let password = sessionStorage.getItem("check");
            let body = {
              lgnId: sessionStorage.getItem("id")!,
              lgnPassword: crypto.AES.decrypt(password!, process.env.REACT_APP_JWT_ACCESS_SECRET_KEY).toString(crypto.enc.Utf8),
            };
            await request(APILIST.LOGIN, "post", undefined, body).then(async (_loginResponse: any) => {
              if (_loginResponse?.result === RESULT.OK) {
                sessionStorage.setItem("accessToken", _loginResponse.data!.accessToken);
                sessionStorage.setItem("refreshToken", _loginResponse.data!.refreshToken);
                sessionStorage.setItem("id", body.lgnId);
                sessionStorage.setItem("check", crypto.AES.encrypt(body.lgnPassword, process.env.REACT_APP_JWT_ACCESS_SECRET_KEY).toString());
              } else if (_loginResponse?.result === RESULT.FAIL) {
                throw Error("로그인 유지 실패");
              }
            });
            res.config.headers.Authorization = `Bearer ${sessionStorage.getItem("accessToken")}`;
          } else {
            window.sessionStorage.setItem("accessToken", response.data!.accessToken);
            res.config.headers.Authorization = `Bearer ${response.data.accessToken}`;
          }
          return axios(res.config);
        }
      );
      return result.data;
    } else {
      return res.data;
    }
  } catch (e: any) {
    return { result: RESULT.FAIL, msg: e ? e.message : "처리 실패" };
  }
}

function getUri<T>(api: APILIST | string, query?: QueryType<T>, server?: 'POINT' | 'CSMS' | 'MEMBERSHIP',
): string {
  let path: string | undefined = process.env.REACT_APP_API_URL ?? "http://localhost:3000/";

  if (server == 'POINT') {
    path = process.env.REACT_APP_COUPON_POINT_API_URL
  } else if (server == "CSMS") {
    path = process.env.REACT_APP_CSMS_API_URL
  } else if (server == "MEMBERSHIP") {
    path = process.env.REACT_APP_MEMBERSHIP_API_HOST
  }
  let url: string = path + "/" + api;
  if (query) {
    let data: string[] = [];
    url = url + "?";
    Object.keys(query).map((key: any) => data.push(key + "=" + query[key]));
    url = url + data.join("&");
  }
  return url;
}
