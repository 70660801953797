export const footerBackground = "#333";
export const footerFontColor = "#CCC";
export const fontColor = "#555555";
export const dividerColor = "rgba(0,0,0,.125)";
export const navMenuFontColor = "#212529";
export const cardTitleColor = "#111";
export const primaryColor = "#ff8116";
export const searchColor = "#808184";
export const searchBorderColor = "#D0D0D0"; //"#bbbdbf";
export const selectColor = "#7bbbf0";
export const listBorderColor = "#707070";
export const noticeFontColor = "#1c272b";
export const noticeRedColor = "#DC3545";
export const noticeBlueColor = "#0D62FD";
export const boxBottomBorderColor = "#dee2e6";
export const inputBorderColor = "#ced4da";
export const radioButtonBorderColor = "#bbbdbf";
export const spanBlueColor = "#3596ff";
export const modalBorderColor = "#dee2e6";
export const modalGreyBtnColor = "#6c757d";
export const modalGreyBtnOpacityColor = "#d1d5d8";
export const greyColor = "#ebeaea";
export const greyColor2 = "#DDD";
export const labelBgColor = "#f7f7f7";
export const orangeColor = "#f2994a";
export const greenColor = "#27ae60";
export const greyBgColor = "rgb(248,249,250)";
export const lightBlueColor = "#2c98f0";
export const stipulationBgColor = "#EEE9E9";
export const disabledFontColor = "#b0b0b0";
export const redColor = "#ff0000";
export const payInfoSubTextColor = "#888888";
export const payInfoOrangeColor = "#FF8115";
export const payInfoSubOrangeColor = "#FFB97D";
export const payInfotableOrangeColor = "#FFF0E3";
export const payInfotableNavyColor = "#868AAE";
export const payInfotableBorder = "#DCDCDC";


export const orangeBtnGradient = "linear-gradient(253.2deg,#f6993f,#ff6900 99.46%)";
export const blueBtnGradient = "linear-gradient(253.2deg,#3596ff,#6e73ff 99%)";
export const greenBtnGradient = "linear-gradient(253.2deg,#0a6f40,#c0dace 90%)";
export const sectionBgGradient = "linear-gradient(114.36deg,#fafaff,#f2f1fb)";

export const minimumWidth = "470px";
export const mobileChangeWidth = "640px";
export const inlineWidth = "1200px";
export const loginChangeWidth = "900px";
export const registerChangeWidth = "900px";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};
export const repayCustomStyles = {
  overlay: {
    zIndex: 1002,
    backgroundColor: "rgba( 0, 0, 0, 0)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    border: 'none',
    boxShadow: '2px 4px 8px 2px rgba(0, 0, 0, 0.2)',
  },
};
export const resendCustomStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba( 61, 61, 61, 0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    border: 'none',
    boxShadow: '2px 4px 8px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
  },
};
export const resendConfirmCustomStyles = {
  overlay: {
    zIndex: 1002,
    backgroundColor: "rgba( 61, 61, 61, 0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    border: 'none',
    boxShadow: '2px 4px 8px 2px rgba(0, 0, 0, 0.2)',
  },
};

export const loginCustomStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};

export const creditCardCustomStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};
